var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{style:({ position: 'relative' })},[_c(_setup.Table,{attrs:{"headers":[
          {
            text: 'Application Number',
            value: 'application_number',

          },

          {
            text: 'Approval Number',
            value: 'application_number',
            fn: (application) => application.building_application.building.approval_number

          },

          {
            text: 'Building Application Number',
            value: 'application_number',
            fn: (application) => application.building_application.application_number

          },

          {
            text: 'Customer Name',
            value: 'application_number',
            fn: (application) => application.building_application.building.building_owner.length > 0 ? application.building_application.building.building_owner[0].clients.customer_name : 'No Client Found'

          },

          {
            text: 'Architect Name',
            value: 'type_of_development',
            fn: (application) => application.building_application.building.building_architect.length > 0 ? application.building_application.building.building_architect[0].architects.first_name + ' ' + application.building_application.building.building_architect[0].architects.last_name : 'No Architect Found'
            
          },

          {
            text: 'Date Created',
            value: 'application_number',
            fn: (application) => _setup.getFormattedBillDate(application.created_at)

          },

          {
            text: 'Status',
            value: 'application_number',
            fn: (application) => application.workflow_status.status_name 

          },

          {
            text: 'Actions',
            value: 'view',
            action: true,

          },


          // { text: 'Pay', value: 'is_renewal', action: true },
        ],"items":_vm.renovations,"loading":_vm.loading,"emptyText":"No Enforcement Applications Found","headClasses":"primary white--text"},scopedSlots:_vm._u([{key:"item.view",fn:function({ item }){return [_c('div',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"button mr-1 primary--text",attrs:{"icon":""},on:{"click":function($event){return _vm.$emit('viewEnf', item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-eye ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("viewMsg")))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"button mr-1 success--text",attrs:{"icon":""},on:{"click":function($event){return _setup.printReceipt(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-printer ")])],1)]}}],null,true)},[_c('span',[_vm._v("Download Receipt")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"button mr-1 success--text",attrs:{"icon":""},on:{"click":function($event){return _vm.$emit('viewBuilding', item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-download ")])],1)]}}],null,true)},[_c('span',[_vm._v("Download PPA2")])])],1)]}},{key:"item.getBill",fn:function({ item: house }){return [_c(_setup.ActionButton,{attrs:{"action":_setup.actions.pay,"inTable":""},on:{"click":function($event){return _vm.$emit('payForHouse', house)}}})]}},{key:"item.getReceipt",fn:function({ item: house }){return [_c(_setup.ActionButton,{attrs:{"action":_setup.actions.pay,"inTable":""},on:{"click":function($event){return _vm.$emit('payForHouse', house)}}})]}}])})],1)])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }