<script setup>
import Table from "@/components/utils/Table.vue";
import {
  getFormattedMoney,
  getFormattedBillDate,
  getPaymode,
  arrayNotEmpty,
} from "@/utils/functions.js";
import { actions } from "@/utils/constants";
import ActionButton from "@/components/utils/ActionButton.vue";
import { onMounted } from "vue"
import { useDownloadFile } from "@/utils/vue_helpers";

const { downloadFile } = useDownloadFile();

const emit = defineEmits(['enfBuildApp'])

const props = defineProps({
  renovations: {
    type: Array,
    default: null,
  },
  loading: {
    type: Boolean,
    default: false,
  },
});

function ViewEnforcement(item){
  console.log("ViewBuilding")
  console.log(item)

}

function onPrintBillClicked(bill_object) {
  downloadFile({ billId: bill_object.id, billNumber: bill_object.bill_number });

}

function onPrintReceipt(receipt_number) {
  downloadFile({ receiptNumber: receipt_number });

}

function printReceipt(item){
  console.log("item_item_item_item")
  console.log(item)

  if (item.building_renovation_bills.bills.bills_invoices?.[0].invoice_receipt?.receipt?.receipt_number){
    //Receipt available
    onPrintReceipt(item.building_renovation_bills.bills.bills_invoices?.[0].invoice_receipt?.receipt?.receipt_number)

  }
  else{
    //bill not paid
    onPrintBillClicked(item.building_renovation_bills.bills)

  }

}

onMounted(() => {
  console.log("onMounted Table")
  console.log(props.renovations)

})

</script>

<template>
  <v-container fluid class="pa-0">
    <v-row>
      <v-col cols="12">
        <div :style="{ position: 'relative' }">
          <Table :headers="[
            {
              text: 'Application Number',
              value: 'application_number',

            },

            {
              text: 'Approval Number',
              value: 'application_number',
              fn: (application) => application.building_application.building.approval_number

            },

            {
              text: 'Building Application Number',
              value: 'application_number',
              fn: (application) => application.building_application.application_number

            },

            {
              text: 'Customer Name',
              value: 'application_number',
              fn: (application) => application.building_application.building.building_owner.length > 0 ? application.building_application.building.building_owner[0].clients.customer_name : 'No Client Found'

            },

            {
              text: 'Architect Name',
              value: 'type_of_development',
              fn: (application) => application.building_application.building.building_architect.length > 0 ? application.building_application.building.building_architect[0].architects.first_name + ' ' + application.building_application.building.building_architect[0].architects.last_name : 'No Architect Found'
              
            },

            {
              text: 'Date Created',
              value: 'application_number',
              fn: (application) => getFormattedBillDate(application.created_at)

            },

            {
              text: 'Status',
              value: 'application_number',
              fn: (application) => application.workflow_status.status_name 

            },

            {
              text: 'Actions',
              value: 'view',
              action: true,

            },


            // { text: 'Pay', value: 'is_renewal', action: true },
          ]" :items="renovations" :loading="loading" emptyText="No Enforcement Applications Found" headClasses="primary white--text">

            <template #item.view="{ item }">
              <div>

                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon class="button mr-1 primary--text" @click="$emit('viewEnf', item)" v-bind="attrs"
                      v-on="on">

                      <v-icon small> mdi-eye </v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t("viewMsg") }}</span>
                </v-tooltip>

                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon class="button mr-1 success--text" @click="printReceipt(item)" v-bind="attrs"
                      v-on="on">

                      <v-icon small> mdi-printer </v-icon>
                    </v-btn>
                  </template>
                  <span>Download Receipt</span>
                </v-tooltip>

                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon class="button mr-1 success--text" @click="$emit('viewBuilding', item)" v-bind="attrs"
                      v-on="on">

                      <v-icon small> mdi-download </v-icon>
                    </v-btn>
                  </template>
                  <span>Download PPA2</span>
                </v-tooltip>

              </div>


            </template>
            <template #item.getBill="{ item: house }">
              <ActionButton :action="actions.pay" inTable @click="$emit('payForHouse', house)" />
            </template>

            <template #item.getReceipt="{ item: house }">
              <ActionButton :action="actions.pay" inTable @click="$emit('payForHouse', house)" />
            </template>
          </Table>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>
