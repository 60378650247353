<script setup>
import { useViewRenovations } from "./buildings_business_logic";
import ViewRenovationsTable from "@/components/buildings/tables/ViewRenovationsTable.vue";
import LabelledField from "@/components/utils/LabelledField.vue";
import { computed, ref, onMounted } from "vue";
import { resetSnackbarOnMount, snackbarData } from "@/components/utils/snackbar/logic";
import { useRouter, useRouterFromInstance } from "@/utils/vue_helpers";
import Pagination from "../utils/Pagination.vue";
import LoadingArea from "../utils/LoadingArea.vue";
import { routes } from "@/router/routes";
import Instructions from "./Instructions.vue";
//import Table from "@/components/utils/Table.vue";

import store from "@/store/index";
import { GET_USER_DATA } from "@/store/getters/user";
import ViewDemolition from "./subModules/ViewDemolition.vue";


resetSnackbarOnMount();

const { router } = useRouterFromInstance();

const { renovationsData, searchModel_renovate } = useViewRenovations('Renovation');

const pages = {
  VIEW_RENOVATION_DETAILS: 0,
  VIEW_RENOVATION_APPLICATION: 1

};

const currentPage = ref(pages.VIEW_RENOVATION_DETAILS); //ref(pages.VIEW_RENOVATION_DETAILS)

const renovationUnderView = ref(null);

function moveToViewHouseTable() {
  currentPage.value = pages.VIEW_RENOVATION_DETAILS;

}

function viewEnforcement(item){
  renovationUnderView.value = item
  currentPage.value = pages.VIEW_RENOVATION_APPLICATION;
  
}

function moveToViewAllEnforcements(){
  currentPage.value = pages.VIEW_RENOVATION_DETAILS;

}

async function onPaginationClicked(newPage) {
  renovationsData.paginationData.currentPage = newPage;

}

onMounted(() => {

})

</script>

<template>
  <v-container fluid class="pa-0">
    <v-tabs-items v-model="currentPage" touchless continuous>
      <v-tab-item>
        <v-row>
          <v-col cols="12">
            <div class="text-h6 text-center font-weight-bold primary--text">
              Renovations
            </div>
          </v-col>
        </v-row>
        <v-row justify-md="center" dense>
          <v-col cols="12" md="6">
            <LabelledField label="Search">
              <v-text-field class="background text-field" outlined dense v-model="searchModel_renovate" clearable />
            </LabelledField>
          </v-col>
        </v-row>
        <LoadingArea :loading="renovationsData.fetchingData" class="mt-4">

          <v-row>
            <v-col cols="12">
              <ViewRenovationsTable :renovations="renovationsData.fetchedData" :loading="renovationsData.fetchingData" @viewEnf="viewEnforcement" @goBackClicked="moveToViewAllEnforcements"/>
            </v-col>
          </v-row>
          <v-row justify="end">
            <v-col cols="auto">
              <Pagination :current-page="renovationsData.paginationData.currentPage"
                :total-number-of-items="renovationsData.paginationData.total"
                :items-per-page="renovationsData.paginationData.perPage" @paginationClicked="renovationsData.setNewPage($event)">
              </Pagination>
            </v-col>
          </v-row>
        </LoadingArea>
      </v-tab-item>

      <v-tab-item :value="pages.VIEW_RENOVATION_APPLICATION">
        <v-row>
          <v-col cols="12">
            <ViewDemolition :renovation_application="renovationUnderView" @goBackClicked="moveToViewHouseTable" />

          </v-col>
        </v-row>
      </v-tab-item>

    </v-tabs-items>
  </v-container>
</template>
